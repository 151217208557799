import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Homepage from "./components/pages/Homepage";
import Header from "./components/organisms/Header/Header";
import Footer from "./components/organisms/Footer/Footer";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const handleToggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <div className={`App ${isDarkMode ? "dark-mode" : ""}`}>
      <Header isDarkMode={isDarkMode} onToggleDarkMode={handleToggleDarkMode} />
      <Homepage
        isDarkMode={isDarkMode}
        onToggleDarkMode={handleToggleDarkMode}
      />
      <Footer isDarkMode={isDarkMode} onToggleDarkMode={handleToggleDarkMode} />
    </div>
  );
}

export default App;
