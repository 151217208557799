import React from "react";
import "./thirdsection.css";

import adobe from "../../shared/skills/xd.svg";
import figma from "../../shared/skills/figma.svg";
import seo from "../../shared/skills/seo.svg";
import aws from "../../shared/skills/aws.svg";
import bootstrap from "../../shared/skills/bootstrap.svg";
import css from "../../shared/skills/css-3.svg";
import git from "../../shared/skills/git.svg";
import jquery from "../../shared/skills/jquery.svg";
import js from "../../shared/skills/js.svg";
import php from "../../shared/skills/php.svg";
import npm from "../../shared/skills/npm.svg";
import api from "../../shared/skills/api.svg";
import ecom from "../../shared/skills/ecomm.svg";
import mongodb from "../../shared/skills/mongodb.svg";
import html from "../../shared/skills/html.svg";
import node from "../../shared/skills/node.png"
import tailwind from "../../shared/skills/tailwind.png"
import shopify from "../../shared/skills/shopify.svg"
import nextjs from "../../shared/skills/nextjslogo.svg"


import headless from "../../shared/skills/headless.svg";

import react from "../../shared/skills/react.svg";
import sass from "../../shared/skills/sass.svg";
import strapi from "../../shared/skills/strapi.svg";
import woocomerce from "../../shared/skills/woocommerce.svg";
import wordpress from "../../shared/skills/wordpress.svg";

const projects = [
  {
    name: "HTML",
    img: html,
  },
  {
    name: "CSS",
    img: css,
  },
  
  {
    name: "JavaScript",
    img: js,
  },
  {
    name: "jQuery",
    img: jquery,
  },
  {
    name: "ReactJS",
    img: react,
  },
  {
    name: "NextJS",
    img: nextjs,
  },
  {
    name: "PHP",
    img: php,
  },


  {
    name: "Wordpress",
    img: wordpress,
  },
  
  {
    name: "Node JS",
    img: node,
  },
  {
    name: "Shopify",
    img: shopify,
  },
  

 
];

const extras = [
 
  {
    name: "Rest API",
    img: api,
  },
  {
    name: "Headless CMS",
    img: headless,
  },
  {
    name: "E-commerce",
    img: ecom,
  },
  {
    name: "MongoDB",
    img: mongodb,
  },
  {
    name: "SCSS",
    img: sass,
  },
  {
    name: "Tailwind",
    img: tailwind,
  },
  {
    name: "Bootstrap",
    img: bootstrap,
  },

  {
    name: "WooCommerce",
    img: woocomerce,
  },
  {
    name: "Strapi",
    img: strapi,
  },
];

const knowledge = [
  {
    name: "Git",
    img: git,
  },
  {
    name: "AWS",
    img: aws,
  },
  {
    name: "NPM",
    img: npm,
  },
  {
    name: "Adobe Xd",
    img: adobe,
  },
  {
    name: "Figma",
    img: figma,
  },

  {
    name: "SEO",
    img: seo,
  },
];

function ThirdSection({ isDarkMode, onToggleDarkMode }) {
  return (
    <>
      <div
        className={`third-section container ${isDarkMode ? "dark-mode" : ""}`}
      >
        <div className="card">
          <div className="card-img"></div>
          <h6>Full Stack Developer</h6>
          <p>
            I like to code things from scratch, and enjoy bringing ideas to life
            in the browser.
          </p>

          <div className="skillset">
            {projects.map((experience) => (
              <div className="skill-div" key={experience.name}>
                <img
                  className="skill-img"
                  src={experience.img}
                  alt={experience.name}
                />
                <p>{experience.name}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="card">
          <div className="card-img"></div>

          <h6>Other Technology & Skills</h6>
          <p>
            I have knowledge on building E-Commerce, Headless CMS, Rest Api,
            Amazon Web Services and Tools
          </p>

          <div className="skillset">
            {extras.map((experience) => (
              <div className="skill-div" key={experience.name}>
                <img
                  className="skill-img"
                  src={experience.img}
                  alt={experience.name}
                />
                <p>{experience.name}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="card">
          <div className="card-img"></div>
          <h6>Extra Skills </h6>
          <p>
            My general knowledge also includes web design and Seo optimization
          </p>

          <div className="skillset">
            {knowledge.map((experience) => (
              <div className="skill-div" key={experience.name}>
                <img
                  className="skill-img"
                  src={experience.img}
                  alt={experience.name}
                />
                <p>{experience.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ThirdSection;
