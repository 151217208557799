import Hamburger from "hamburger-react";
import React, { useState } from "react";
import "../Header/Header.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {Link} from 'react-scroll'

function Header({ isDarkMode, onToggleDarkMode }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
   
  };
 

  return (
    <nav className={`navbar ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="navbar-brand">
        <a href="">
          <img
            src="https://mattfarley.ca/img/mf-avatar.svg"
            alt="Logo"
            className="logo"
          />
        </a>
        <p className="myname">aganhaziri</p>
        {/* <button className="navbar-toggler" onClick={toggleNav}>
          <span className={burgerClass}>&nbsp;</span>
        </button> */}

        <Hamburger
          className="navbar-toggler"
          onClick={toggleNav}
          toggled={isOpen}
          toggle={setIsOpen}
        />
      </div>
      <div className={`navbar-collapse ${isOpen ? "show" : ""}`}>
        <ul className={`navbar-nav ${isOpen ? "show" : ""}`}>
          <li className="nav-item">
            
            <Link  to="fourth" spy={true} smooth={true} duration={1800}  className="nav-link" onClick={toggleNav}>Projects</Link>
             
         
          </li>
          <li className="nav-item">
          <Link  to="fifth" spy={true} smooth={true} duration={2800}  className="nav-link" onClick={toggleNav}>Experience</Link>
          </li>
          <li className="nav-item">
          <Link  to="contact-form" spy={true} smooth={true}  duration={4000} className="nav-link" onClick={toggleNav}>Contact</Link>
          </li>
          <li className="nav-item">
            <div>
              <input
                type="checkbox"
                className="checkbox"
                id="checkbox"
                aria-label="checkbox"
                onChange={onToggleDarkMode}
              />
              <label className="label" htmlFor="checkbox">
                <i className="fas fa-moon"></i>
                <i className="fas fa-sun"></i>
                <div className="ball"></div>
              </label>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
