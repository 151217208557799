import React, { useState } from "react";
import FirstSection from "../Homepage/FirstSection";
import SecondSection from "../Homepage/SecondSection/SecondSection";
import ThirdSection from "../Homepage/ThirdSection/ThirdSection";
import FourthSection from "../Homepage/FourthSection/FourthSection";
import FifthSection from "../Homepage/FifthSection/FifthSection";

function Homepage({ isDarkMode, onToggleDarkMode }) {
  return (
    <>
      <FirstSection isDarkMode={isDarkMode} />
      <SecondSection isDarkMode={isDarkMode} />
      <ThirdSection isDarkMode={isDarkMode} />
      <FourthSection isDarkMode={isDarkMode} />
      <FifthSection isDarkMode={isDarkMode} />
    </>
  );
}

export default Homepage;
