import React from "react";
import "./firstsection.css";

function FirstSection({ isDarkMode, onToggleDarkMode }) {
  return (
    <div className={`first-section ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="container">

      
      <h1>Hi, I'm Agan Haziri</h1>
      <h2>Full Stack Software Developer</h2>

      <p><i>I design and code beautifully simple things, and I love what I do!</i></p>

      <img
        src="https://mattfarley.ca/img/hero-devices.svg"
        alt="Logo"
        className="logo"
      />
      </div>
    </div>
  );
}

export default FirstSection;
