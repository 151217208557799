import React from "react";
import "./secondsection.css";

function SecondSection({ isDarkMode, onToggleDarkMode }) {
  return (
    <>
      <div className={`second-section ${isDarkMode ? "dark-mode" : ""}`}>
        <div className="container">
          <h5>I’m Agan. Nice to meet you!</h5>
          <p>
            Accomplished Software Developer with a robust background in
            developing scalable, high-quality web and mobile apps across
            multiple platforms. <br></br> Proficient in web/mobile development
            with extensive experience in the MERN stack, WordPress, and mobile
            development using React Native.<br></br>  Passionate about leveraging technical skills to
            create user-centric solutions and drive business success.
          </p>
        </div>
      </div>
    </>
  );
}

export default SecondSection;
