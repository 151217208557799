import React, { useState } from "react";

import "./fifthsection.css";

function FifthSection({ isDarkMode, onToggleDarkMode }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const experiences = [
    {
      jobTitle: "Full Stack Developer",
      date: "03/2023 - present",
      location: "Dublin, Ireland",
      company: "Formify AI - Form builder",
      responsibilities: [
        "Architected and implemented a feature-rich front-end using the MERN stack, leading to significant performance enhancements.",
        "Developed custom drag-and-drop form builder integrated with multiple e-commerce and CRM platforms.",
        "Superheaded the development of intuitive features, significantly enhancing user interactivity and experience. ",
        "Managed AWS server environments and led DevOps practices to streamline development and deployment processes",
      ],
    },
    {
      jobTitle: "Full Stack Developer / Mobile Developer ",
      date: "12/2022 - present",
      location: "London, United Kingdom",
      company: "Creative Deer",
      responsibilities: [
        "Developed multiple mobile applications focused on language learning, work management, and community engagement using React Native.",
        "Created language learning web app using NextJS and Strapi.",
        "Created advanced WordPress themes and plugins from scratch, enhancing website functionality and SEO performance.",
        "Implemented payment gateways, user authentication and delivered highly interactive platforms.",
      ],
    },
    {
      jobTitle: "Full Stack Developer",
      date: "02/2021 - 03/2023",
      location: "Prishtina, Kosovo",
      company: "Black Bird Marketing",
      responsibilities: [
        "Implemented headless CMS solutions with ReactJS, NextJS, Wordpress, PHP and Strapi, improving site performance, editorial flexibility and scalability.",
        "Delivered custom software solutions for diverse industries, including e-commerce, educational platforms, and hotel booking systems.",
        "Played a key role in website migrations and API integrations, enhancing site functionality and user engagement.",
        "AWS server management & DevOps.",
        "Expertly utilized page builders such as Elementor and Beaver Builder to create bespoke, responsive designs that elevate brand identity. "
      ],
    },
    {
      jobTitle: "Front End Developer",
      date: "09/2020 - 02/2021",
      location: "Chur, Switzerland ",
      company: "QROrpa",
      responsibilities: [
       "Designed and developed a user-friendly restaurant booking system with enhanced customer engagement features.",
        "Managed full lifecycle of web design using AdobeXD and Figma, from conceptualization to deployment"
      ],

    },

    {
      jobTitle: "Front End Developer",
      date: "09/2020 - 03/2021",
      location: "Chur, Switzerland ",
      company: "Starlabs",
      responsibilities: [
        "Developed an interactive online library website with user registration, book purchases, and community features."
      ],
      
    },

    {
      jobTitle: "Web Developer ",
      date: "03/2019 - 08/2021",
      location: "Kosovo",
      company: "Freelance",
      responsibilities: [
        "Developed e-commerce and informational websites for local businesses."
      ],
      
    },
  ];

  return (
    <div
      id="fifth"
      className={`fifth-section ${isDarkMode ? "dark-mode" : ""}`}
    >
      <h3>Experience</h3>
      <div className="experience-container container">
        {experiences.map((experience, index) => (
          <div
            key={index}
            className={`single-experience ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => handleToggle(index)}
          >
            <div className="experience-brief">
              <h6>{experience.jobTitle}</h6>

              <h6>{experience.date}</h6>
            </div>
            <div className="experience-desc">
              <p className="location">{experience.location}</p>
              <p className="location">{experience.company}</p>

              <ul>
                {experience.responsibilities.map((responsibility, index) => (
                  <li key={index}>{responsibility}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FifthSection;
