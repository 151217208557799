import React, { useState } from "react";
import "./Footer.css";
import em from "../../shared/email.svg";
import fb from "../../shared/facebook.svg";
import uw from "../../shared/upwork.png"
import ig from "../../shared/instagram.svg";
import gh from "../../shared/github.svg";
import wa from "../../shared/whatsapp.svg";
import emailjs from "emailjs-com";

function Footer({ isDarkMode, onToggleDarkMode }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    const errors = {};
    if (
      !formData.name ||
      formData.name.length < 3 ||
      formData.name.length > 50
    ) {
      errors.name = "Name must be between 3 and 50 characters!";
    }

    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid.";
    }

    if (
      !formData.message ||
      formData.message.length < 10 ||
      formData.message.length > 200
    ) {
      errors.message = "Message must be between 10 and 200 characters.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    emailjs
      .sendForm(
        "service_tbups8q",
        "template_lx4a2si",
        e.target,
        "tvLwT5D4qB1ssinTl"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccess(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  function validateForm(formData) {
    const errors = {};

    // Validate name field
    if (!formData.name) {
      errors.name = "Please enter your name.";
    } else if (!/^[A-Za-z\s]{3,50}$/.test(formData.name)) {
      errors.name =
        "Name must contain only letters and be between 3 and 50 characters.";
    }

    // Validate email field
    if (!formData.email) {
      errors.email = "Please enter your email address.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    // Validate message field
    if (!formData.message) {
      errors.message = "Please enter a message.";
    } else if (formData.message.length < 10 || formData.message.length > 200) {
      errors.message = "Message must be between 10 and 200 characters.";
    }

    return errors;
  }

  return (
    <>
      <div className={`footer ${isDarkMode ? "dark-mode" : ""}`}>
        <div className="start-project">
          <div className="sp-col">
            {" "}
            <h3>Start a project</h3>
          </div>
          <div className="sp-col">
            <p>
              Interested in working together? We should queue up a time to chat.
              I’ll buy the coffee.
            </p>
          </div>
          <div className="sp-col">
            <button>Let's do this</button>
          </div>
        </div>
        <div className="footer-btm">
          <h6>Living, learning, & leveling up one day at a time.</h6>
          <form id="contact-form" onSubmit={sendEmail}>
            <h3>Contact Me</h3>
            {errors.name && <div className="error">{errors.name}</div>}
            <input
              id="fullname"
              type="text"
              name="from_name"
              placeholder="Full Name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              onFocus={() => setErrors({ ...errors, name: "" })}
            />

            {errors.email && <div className="error">{errors.email}</div>}
            <input
              id="email"
              type="email"
              name="from_email"
              placeholder="Your Email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              onFocus={() => setErrors({ ...errors, email: "" })}
            />

            {errors.message && <div className="error">{errors.message}</div>}
            <textarea
              id="message"
              name="message"
              placeholder="Your message here.."
              rows={10}
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              onFocus={() => setErrors({ ...errors, message: "" })}
            />

            <input id="submitForm" type="submit" value="Send" />
            {showSuccess && (
              <div className="success">
                Thank you! Your message has been sent.
              </div>
            )}
          </form>
          <div className="socials">
            <a href="mailto:aganhaziri@gmail.com">
               <img src={em} alt="fb" width={"40px"} height={"100%"}></img>
            </a>
            <a href="https://www.facebook.com/aganDFC">
               <img src={fb} alt="fb" width={"40px"} height={"100%"}></img>
            </a>

            <a href="https://www.instagram.com/aganhazirii/">
              <img src={ig} alt="fb" width={"40px"} height={"100%"}></img>
            </a>

            <a href="https://www.upwork.com/freelancers/~01009c9300c160a463">
              <img src={uw} alt="fb" width={"40px"} height={"100%"}></img>
            </a>

            <a href="https://github.com/agan-bbm">
              <img src={gh} alt="fb" width={"40px"} height={"100%"}></img>
            </a>

          </div>
          <p>Handcrafted by aganhaziri © 2023</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
