import React from "react";
import "./fourthsection.css";
import blackbird from "../../images/blackbird.webp";
import blog from "../../images/blog.webp";
import busterbox from "../../images/BUSTERBOX.webp";
import cleanfit from "../../images/cleanfit.webp";
import cooper from "../../images/COOPER.webp";
import dukagjini from "../../images/DUKAGJINI.webp";
import formify from "../../images/formify.webp";
import intersport from "../../images/INTERSPORT.webp";
import kusa from "../../images/kusa.webp";
import mib from "../../images/MIB.webp";
import omneeweb from "../../images/OMNEEWEB.webp";
import prmall from "../../images/prmall.webp";
import provit from "../../images/PROVIT.webp";
import sit from "../../images/sit.webp";
import { Link } from "react-router-dom";

function FourthSection({ isDarkMode, onToggleDarkMode }) {
  const projects = [
    {
      featured: "Formify",
      name: "Blackbird",
      link: "https://www.getformify.com/",
      background: formify,
      explanation:
        "Formify is a software-as-a-service business with a product that allows businesses to build custom step-based forms and surveys that make it easy to capture high-value data from prospects. ",
      technologies: ["React JS", "MongoDB", "NodeJS", "ExpressJS"],
    },
    {
      featured: "Intersport",
      name: "Blackbird",
      link: "https://www.intersportks.com",
      background: intersport,
      explanation:
        "Intersport is a local sports specialist retailer, with the biggest brands like Nike, Adidas, ASICS, Puma, New Balance, and more, and located in more than 6000 different locations.",
      technologies: ["Wordpress", "WooCommerce", "PHP", "JavaScript", "jQuery"],
    },
    {
      featured: "Busterbox",
      name: "Blackbird",
      link: "https://busterbox.com/",
      background: busterbox,
      explanation:
        "BusterBox is a monthly package of goodies for your dog! The UK & Ireland's leading dog subscription box company, delighting thousands of happy dogs with all of their favourite things every single month.",
      technologies: [
        "Wordpress",
        "Custom Theme",
        "PHP",
        "Subbly",
        "ActiveCampaign",
      ],
    },
    {
      featured: "Cleanfit",
      name: "Blackbird",
      link: "https://cleanfitbox.com/",
      background: cleanfit,
      explanation:
        "Cleanfit is a monthly box of healthy snacks and superfoods that are ACTUALLY healthy! We read the labels for you and make sure there's no junk inside 🙅 So you can EAT CLEAN to support your long-term wellness goals!.",
      technologies: ["React JS", "Rest API", "Strapi", "Subbly", "Mailchimp"],
    },
    {
      featured: "MyIrelandBox",
      name: "Blackbird",
      link: "https://myirelandbox.com/",
      background: mib,
      explanation:
        "MyIrelandBox is a premium subscription service for those who wish to discover Ireland and Irish Design from talented makers based all over Ireland.",
      technologies: ["React JS", "Wordpress", "Rest API"],
    },
    {
      featured: "Cooper and Kid",
      name: "Blackbird",
      link: "https://cooperandkid.com/",
      background: cooper,
      explanation:
        "Cooper & Kid first launched as a subscription box for dads as one of the first and few parenting brands speaking to men. Over the years we discovered that many moms, teachers, nannies, homeschoolers and grandparents are subscribing and using the Cooper Kits, too.",
      technologies: ["React JS", "Rest API", "Wordpress", "KEAP"],
    },
    {
      featured: "Dukagjini Hotel",
      name: "Blackbird",
      link: "https://hoteldukagjini.com/",
      background: dukagjini,
      explanation:
        "Hotel Dukagjini always was known as one of the Elite Hotels in Kosovo. The Hotel is combined so well , by combining original with modern while new domestic premises reflects elegance charming.",
      technologies: ["React JS", "Rest API", "Wordpress"],
    },
    {
      featured: "Blackbird",
      name: "Blackbird",
      link: "https://blackbird.marketing/",
      background: blackbird,
      explanation:
        "An agency portfolio website of one of the biggest Marketing Agencies in Kosovo! Blackbird is a marketing agency that provides solution on digital marketing, web development, branding, business growth and social media management.",
      technologies: ["React", "PHP"],
    },
    {
      featured: "Omneeweb",
      name: "Blackbird",
      link: "https://www.omneeweb.de/",
      background: omneeweb,
      explanation:
        "An agency portfolio website for Omneeweb! Omneeweb is a marketing agency that provides solution on digital marketing, web development, branding, business growth and social media management mainly in Germany.",
      technologies: ["React JS", "Gatsby", "Scss"],
    },
    {
      featured: "Prishtina Mall",
      name: "Blackbird",
      link: "https://prishtinamall.com/",
      background: prmall,
      explanation:
        "Web App for Prishtina Mall, which is the largest sector leading shopping entertainment mall in Southeast Europe.",
      technologies: ["HTML", "CSS", "JavaScript"],
    },
    {
      featured: "Kusa",
      name: "Blackbird",
      link: "https://kusalumni.org/",
      background: kusa,
      explanation:
        "Kosovo United States Alumni is a nonprofit association, established in 2007 with the help of the U.S. Embassy in Kosovo and its founding board.",
      technologies: ["Wordpress", "Elementor", "jQuery"],
    },
    {
      featured: "SIT",
      name: "Blackbird",
      link: "https://sit-ks.org/",
      background: sit,
      explanation:
        "Sit & Talk is a free platform designed to help all people who are going through different problems from everyday life, from different life events and for all those who need to talk to someone. The platform enables sessions to be held from any place and time with licensed therapists.",
      technologies: ["Wordpress", "PHP", "Custom Theme"],
    },
    {
      featured: "Provit",
      name: "Blackbird",
      link: "https://provit-ks.com/",
      background: provit,
      explanation:
        "Provit is an international protein and vitamins retailer licensed by global fitness brands, including Muscletech, Optimum Nutrition, Mutant, BSN, Universal, Animal etc.",
      technologies: ["Wordpress", "PHP", "WooCommerce"],
    },
    {
      featured: "Busterbox Blog",
      name: "Blackbird",
      link: "https://busterbox.com/blog/",
      background: blog,
      explanation:
        "A blog website of The UK & Ireland's leading dog subscription box company, delighting thousands of happy dogs with all of their favourite toys & treats every single month.",
      technologies: ["Wordpress", "Custom Theme", "PHP", "Elementor"],
    },
  ];
  // console.log(projects);
  return (
    <div id="fourth" className={`fourthsection container ${isDarkMode ? "dark-mode" : ""}`}>
      <h6>Some Things I’ve Built</h6>
      <p>
        Here are a few past design projects I've worked on. Want to see more?
        <a className={`emailme ${isDarkMode ? "dark-text" : "light-text"}`} id="emailme" href="mailto:aganhaziri@gmail.com"> Email me.</a>
      </p>{" "}
      <div className="projects-holder container">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <div className="project-image">
              <img
                src={project.background}
                alt="project-bg"
                width={"120%"}
                height={"100%"}
              ></img>
            </div>

            <div className="project-details">
              <h3 className="project-title">{project.featured}</h3>
              <div className="description">
                <p className="project-description">{project.explanation}</p>
              </div>
              <ul className="project-tech-list">
                {project.technologies.map((technology, index) => (
                  <li key={index}>{technology}</li>
                ))}
              </ul>
              <Link
                className="project-link"
                to={project.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-external-link"
                >
                  <title>External Link</title>
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                  <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                </svg>
              </Link>
            </div>
          </div>
        ))}
      </div>
      {/* <button className="see-more-btn button-default ">
        See more on Github
      </button> */}
    </div>
  );
}

export default FourthSection;
